<template>
  <div>Sie wurden abgemeldet</div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("logout");
  }
};
</script>
